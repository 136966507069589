import * as React from "react"
import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import Gallery from "../components/Gallery"
import Contact from "../components/Contact"
import "../theme/main.less"

import heizungenGallery from "../data/heizungenGallery"

const HeizungenPage = () => {
  return (
    <>
      <HelmetSlot
        title="Heizungen und Sanitär"
        description="Wir sind tätig im Bereich Heizungen und Sanitär. Rufen Sie uns an. Telefon: 02241 1696682"
      />
      <main>
        <body className="sanitaerpage">
          <div className="view">
            <div className="landing">
              <Navigation />
            </div>
            <div className="main">
              <h1>Heizungen & Gasanlagen</h1>
              <div className="content">
                <p className="text">
                  Ihre Wünsche und Vorstellungen im Bereich Heizung und Sanitär
                  setzen wir kompetent in passende Lösungen um. Ob Gas, Öl oder
                  Pellets- Heizungen, Brennwert-Technik oder Wärmepumpen, wir
                  beraten sie gerne und finden mit ihnen gemeinsam die beste
                  Lösung für ihre individuelle Situation. Neben den bekannten
                  Marken wie Vießmann und Junkers führen wir auch andere Marken
                  weniger bekannter Anbeiter und greifen damit auf ein breites
                  Portfolio zu.
                  <br />
                  <br />
                  Reparaturen, Neubau oder Austausch - bei uns sind sie richtig.
                  Gerne übernehmen wir anschließend auch regelmäßige Wartung und
                  Inspekstionen ihrer Anlage(n). Auf Wunsch vernetzen wir ihre
                  Anlage und ebnen so den Weg in ihr Smarthome.
                </p>
                <Contact />
              </div>
              <br></br>
              <h4>Bilder und Impressionen</h4>
              <Gallery items={heizungenGallery} />
              <div></div>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default HeizungenPage
