const sanitaerGallery = [
  {
    original: "https://i.imgur.com/fm0EoBC.jpg",
    thumbnail: "https://i.imgur.com/fm0EoBC.jpg",
    description: "Heizzentrale in einem Wohn- und Geschäftshaus.",
  },
  {
    original: "https://i.imgur.com/QHjggkP.jpg",
    thumbnail: "https://i.imgur.com/QHjggkP.jpg",
    description: "Die erste Musteranlage dieser Art in Deutschland.",
  },
  {
    original: "https://i.imgur.com/HzDHRQz.jpg",
    thumbnail: "https://i.imgur.com/HzDHRQz.jpg",
  },
]

export default sanitaerGallery
